var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-manage" }, [
      _c("div", { attrs: { id: "blog-topImg" } }),
      _c("div", { staticClass: "blog-title-text mt-5 text-center" }, [
        _vm._v("ヤマラボリリースしました")
      ]),
      _c("div", { staticClass: "container pb-5" }, [
        _c("div", { staticClass: "text-center blog-content-text" }, [
          _c("div", { staticClass: "my-3" }, [_vm._v("2020/07/30")]),
          _c("div", { staticClass: "mt-5 text-left" }, [
            _c("p"),
            _c("p"),
            _c("p", [_vm._v("こんにちは、ヤマラボ管理人です。")]),
            _c("p"),
            _c("p", [
              _vm._v(
                "この度、クライマーのための新サービス「ヤマラボ」をリリースいたしました。"
              )
            ]),
            _c("p"),
            _c("p", [
              _c("img", {
                staticClass: "blogImg",
                attrs: { src: "/img/blog/release_img.jpg", alt: "" }
              })
            ]),
            _c("p"),
            _c("p", [
              _vm._v(
                "ヤマラボはボルダリングやロープクライミングの岩場を探したり、トポを確認したり、クライミングの記録を残したりできるウェブサービスです。"
              )
            ]),
            _c("p", [
              _c("span", { staticStyle: { color: "#3366ff" } }, [
                _c(
                  "a",
                  {
                    staticClass: "blog-text-color",
                    staticStyle: { color: "#3366ff" },
                    attrs: {
                      href: "https://www.yamalab.jp/",
                      target: "_blank",
                      rel: "noopener noreferrer"
                    }
                  },
                  [_vm._v("https://www.yamalab.jp/")]
                )
              ])
            ]),
            _c("p"),
            _c("p", [
              _vm._v("トポの写真は全てヤマラボスタッフが撮影しています。")
            ]),
            _c("p"),
            _c("p", [
              _vm._v(
                "まだ撮影できていない岩場が多数ありますので、随時追加していく予定です。"
              )
            ]),
            _c("p"),
            _c("p", [
              _vm._v(
                "また、プライベートで登りに行くスタッフもいるので、その際の岩の情報もここでお伝え出来たらなと思っております。"
              )
            ]),
            _c("p"),
            _c("p", [
              _vm._v(
                "より多くのボルダー、クライマーの皆さんにご利用いただけるようサービスを充実させていきますので、「ヤマラボ」をどうぞよろしくお願いいたします。"
              )
            ]),
            _c("p"),
            _c("p")
          ]),
          _c("hr", {
            staticClass: "mt-5",
            attrs: { color: "#CCCCCC", width: "100%", size: "3" }
          }),
          _c("a", { staticClass: "d-inline-block", attrs: { href: "/blog" } }, [
            _c("div", { staticClass: "mt-5 blog-button px-4 py-1" }, [
              _vm._v("ブログ一覧")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }